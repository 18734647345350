/* eslint-disable prettier/prettier */
export default {
    'Daily': 'दैनिक',
    'Weekly': 'साप्ताहिक',
    'Monthly': 'मासिक',
    'RS.': 'रुपये ',
    'WITHDRAWAL': 'निकासी',
    'PHONE NUMBER': 'फ़ोन नंबर',
    'WITHDRAW AMOUNT': 'निकासी राशि',
    'Rs:': 'रुपए:',
    'SUBMIT': 'सबमिट',
    'Close': 'बंद करे',
    'GUEST': 'अतिथि',
    'Sign Up': 'साइन अप करें',
    'VERIFICATION': 'सत्यापन',
    'Password confirm mismatch': 'पासवर्ड की पुष्टि मिसमैच है',
    'Invalid mobile phone number': 'अमान्य मोबाइल फ़ोन नंबर',
    'Your referral link copied.': 'आपका रेफ़रल लिंक कॉपी हो गया।',
    'Your promotional link copied.': 'आपका प्रोमोशन लिंक कॉपी हो गया।',
    'Successful': 'सफल',
    'Login info expires, please login.': 'लॉगिन जानकारी समाप्त हो रही है, कृपया लॉगिन करें।',
    'Something went wrong for get share config.': 'शेयर कॉन्फिगर पाने में कुछ गड़बड़ हो गई।',
    'Verify code was sent to your phone number': 'सत्यापन कोड आपके फ़ोन नंबर पर भेजा गया था',
    'Send too many sms, please wait a momnt or use another phone number.': 'अनेक एसएमएस भेजें, कृपया कुछ पल इंतजार करें या अन्‍य फोन नंबर का उपयोग करें।',
    'This phone number is used. Go to login or use another.': 'इस फोन नंबर का उपयोग किया गया है। लॉगिन करें या अन्य का उपयोग करें।',
    'Failed to send verify code sms, please check your phone number and retry.': 'सत्‍यापन कोड एसएमएस भेजने में विफल, कृपया अपना फ़ोन नंबर देखें और फि‍र कोशिश करें।',
    'Your verify code mismatch with phone numer': 'आपके फ़ोन नंबर से आपका सत्‍यापन कोड नहीं मिलता',
    'This phone numer has been registered!': 'यह फ़ोन नंबर रजिस्‍टर हो गया है!',
    'Something went wrong with server, please retry later.': 'सर्वर में कुछ गड़बड़ हुई, कृपया बाद में फि‍र कोशिश करें।',
    'Your phone number or password is incorrect, check and retry.': 'आपका फ़ोन नंबर या पासवर्ड गलत है, देखें और फि‍र कोशिश करें।',
    'Your password has been changed.': 'आपका पासवर्ड बदल दिया गया है।',
    'Access denied, please retry': 'एक्‍सेस से इनकार, कृपया फि‍र कोशिश करें',
    'Your phone number is incorrect, check and retry.': 'आपका फ़ोन नंबर गलत है, देखें और फि‍र कोशिश करें।',
    'share failed or canceled': 'शेयर करना विफल या केंसल किया गया',
    'FaceBook': 'फेसबुक',
    'WhatsApp': 'व्हाट्सएप',
    'Type your phone number': 'आप फोन नंबर टाइप करें',
    'Withdraw in progress, check and handle it first.': 'निकासी प्रगति में है, पहले इसे देखें और संभालें।',
    'At least {0} can be withdrawn': 'कम से कम {0} रुपए की निकासी की जा सकती है',
    'At most {0} withdraw in 24 hours': '24 घंटे में अधिकतम {0} निकासी',
    'Phone number required': 'फोन नंबर आवश्यक',
    'OTP required': 'OTP आवश्यक',
    'Please signup before your withdrawal': 'कृपया अपनी वापसी से पहले साइन अप करें',
    'Withdraw amount': 'निकासी राशि',
    'UPI': 'UPI',
    'Requires your UPI ID': 'अपने UPI आईडी की आवश्यकता है',
    'UPI ID': 'UPI आईडी',
    'Bank account': 'बैंक खाता',
    'Requires account number & IFSC code': 'खाता संख्या और IFSC कोड की आवश्यकता है',
    'BANK ACCOUNT NUMBER': 'बैंक खाता संख्या',
    'Type your bank account number': 'अपना बैंक खाता नंबर टाइप करें',
    'CONFIRM BANK ACCOUNT NUMBER': 'बैंक खाता संख्या की पुष्टि करें',
    'Confirm your bank account number': 'अपने बैंक खाते की संख्या की पुष्टि करें',
    'BANK ACCOUNT HOLDER NAME': 'बैंक खाता के धारक का नाम',
    'Type your bank account holder name': 'अपना बैंक खाता धारक नाम लिखें',
    'IFSC CODE': 'IFSC कोड',
    'Type your IFSC code': 'अपना IFSC कोड टाइप करें',
    'Please select UPI or Bank account for your withdrawal': 'कृपया अपनी निकासी के लिए UPI या बैंक खाते का चयन करें',
    'UPI ID required': 'UPI आईडी की आवश्यकता',
    'Bank account number required': 'बैंक खाता संख्या आवश्यक',
    'Bank account number confirmation does not match': 'बैंक खाता संख्या की पुष्टि मेल नहीं खाती',
    'Bank account holder name required': 'बैंक खाता धारक का नाम आवश्यक',
    'Bank account IFSC code required': 'बैंक खाता IFSC कोड आवश्यक है',
    '₹': '₹',

    'Refer & Earn': 'पैसे कैसे कमाएं?',
    '{0} invited {1} friends, got {2} Rs. commission': '{0} {1} मित्र आमंत्रित किए और ₹ {2} कमीशन मिला',
    'Current Balance': 'वर्तमान कमीशन',
    'T&C': 'नियम & शर्तें',
    'Withdraw': 'निकासी',
    'Discounts Store': 'स्टोर पर छूट',
    'Rules': 'नियम',
    'My Commission': 'मेरा कमीशन',
    'My Affiliates': 'मेरे सहयोगी',
    'Copy&Send': 'कॉपी & भेजें',
    'More': 'अधिक',
    'Contents': 'विषय सामग्री',
    'How to earn commission?': 'कमीशन कैसे कमाएं?',
    'How to invite your friends?': 'अपने मित्र कैसे आमंत्रित करें?',
    'What’s the purpose of divisions?': 'विभाजन का क्‍या उद्देश्य है?',
    'How to upgrade your division?': 'अपना विभाजन कैसे अपग्रेड करें?',
    'How to develop your referrals into affiliates?': 'सहयोगियों को अपने रेफरल में कैसे विकसित करें?',
    'How to get commission from your affiliates?': 'अपने सहयोगियों से कमीशन कैसे पाएं?',

    '1.How to earn commission?': '1. कमीशन कैसे कमाएं?',
    'Four ways to earn huge amount of commission': 'कमीशन की भारी रकम कमाने के चार तरीके',
    'Invite your friends to download the App, you can get commission from invitation. Normally it’s ₹20 for per person.': 'ऐप डाउनलोड करने के लिए अपने मित्र आमंत्रित करें, निमंत्रण से आप कमीशन पा सकते हैं। आमतौर पर हर व्यक्ति के लिए यह ₹20 है।',
    'You can get commission from recharging, up to 30%, of every recharge of your referrals1. The commission is valid forever. The higher division2 you are, the higher percentage of commission you will get.': 'अपने रेफरल1 के हर रिचार्ज का 30% तक आप रिचार्जिंग से कमीशन पा सकते हैं। कमीशन सदा के लिए वैध है। आपका जितना उच्‍च डिवीज़न2 होगा, आपको उतना उच्च कमीशन मिलेगा।',
    'You can also get commission from affiliates3, which means you can earn commission from your affiliates’ referrals;The higher division you are, the more commission you will get.': 'सहयोगियों3 से भी आप कमीशन पा सकते हैं, जिसका अर्थ है आप अपने सहयोगियों के रेफरल से कमीशन कमा सकते हैं; आपका डिवीजन जितना उच्च होगा, आप उतना अधिक कमीशन पाएंगे।',
    'When you reach silver division, you can buy discounted chips in discounts store and trade with your referrals. So you can gain profits, up to 20%, due to the price difference. The higher division you are, the higher discount rate you will get.': 'आपके सिल्वर डिवीज़न में पहुँचने पर, डिस्काउंट स्टोर से रियायती चिप्‍स खरीदकर अपने रेफर किए गए से आप ट्रेड कर सकते हैं। इस तरह कीमत के अंतर से आपको 20% तक का फायदा हो सकता है। आपका डिवीजन जितना ऊंचा होगा, आपको उतनी अधिक रियायती दर मिलेगी।',
    
    '2.How to invite your friends?': 'अपने मित्रों को कैसे आमंत्रित करें?',
    'Share the game via social media or share the referral link to your friends.': 'गेम को सोशल मीडिया के माध्यम से शेयर करें या रेफरल लिंक अपने मित्रों को शेयर करें।',
    'You get commission after your friends click the promotional link, download and install the game.': 'प्रमोशन लिंक पर आपके मित्रों द्वारा क्लिक कर, डाऊनलोड और गेम इंस्‍टाल करने पर आपको कमीशन मिलता है।',
    'The installation must be finished by the referral link. Sharing the App package by Sharelt etc is not allowed.': 'इंस्‍टालेशन को रेफरल लिंक से पूरा किया जाना चाहिए। ऐप पैकेज को Sharelt आदि से शेयर करने की अनुमति नहीं है।',
    'The App is available for Android users. The App for IOS is coming soon.': 'ऐप एंड्रॉइड यूजरों के लिए उपलब्ध है। IOS के लिए ऐप जल्द ही आ रहा है।',

    '3.What’s the purpose of divisions?': '3. डिवीजन का क्‍या उद्देश्य है?',
    'All promoters are divided into five divisions, from the lowest to the highest: bronze division, silver division, gold division, platinum division and diamond division.': 'समस्‍त प्रमोटरों को पांच डिवीजनों में विभाजित किया गया है, कांस्य डिवीजन, सिल्‍वर डिवीजन, गोल्ड डिवीजन, प्लैटिनम डिवीजन और डायमंड डिवीजन।',
    'The higher division you are, the more ways of earning commission you will get.': 'आप जितने उच्च डिवीजन में होंगे, आपकी कमाई के उतने अधिक रास्‍ते होंगे।',
    'The higher division you are, the higher percentage you will get in terms of commission from recharging.': 'आप जितने उच्‍च डिवीजन में होंगे, कमीशन और रिचार्जिंग के मामले में आप उतना अधिक प्रतिशत पाएंगे।',
    'The higher division you are, the more commission from affiliates you will get.': 'आप जितने उच्‍च डिवीजन में होंगे, सहयोगियों से आप उतना अधिक कमीशन पाएंगे।',

    '4.How to upgrade your division?': '4. अपना डिवीजन कैसे अपग्रेड करें?',

    'Your get higher division if more referrals you bring to the game and the more they recharge.': 'गेम में आपके अधिक रेफरल लाने और उनके अधिक रिचार्ज करने पर आप उच्च डिवीजन पाएंगे।',
    'If you have extraordinary promotional ability with channels, please get contact with our customer service center to verify your division.': 'चैनलों में आपकी असाधारण प्रमोशन क्षमता होने पर, अपना डिवीजन सत्यापित करवाने के लिए कृपया हमारे कस्‍टमर सर्विस सेंटर से संपर्क करें।',
    'We will verify the quality of our promoters regularly and those unqualified promoters will be degraded.': 'अपने प्रमोटरों की गुणवत्ता हम नियमित रूप से सत्यापित करेंगे और अक्षम प्रमोटर पदावनत किए जाएंगे।',

    '5.How to develop your referrals into affiliates?': '5. अपने रेफरल को सहयोगियों में कैसे विकसित करें?',
    'Encourage your referrals to invite their friends to play the game, then your referrals will become your affiliates.': 'गेम खेलने के लिए अपने मित्रों को रेफरल आमंत्रित करने हेतु प्रोत्साहित करें, तब आपके रेफरल आपके सहयोगी बनेंगे।',
    'Share more promotion ways to your affiliates, so they can develop their referrals quickly and your commission will increase too.': 'प्रमोशन के अधिक अवसर अपने सहयोगियों से शेयर करें, जिससे वे अपने रेफरल जल्द बढ़ा सकें और आपका कमीशन भी बढ़ेगा।',


    '6.How to get commission from your affiliates?': '6.अपने सहयोगियों से कमीशन कैसे पाएं?',
    'You will get a percentage of commission from your affiliates’ commission.': 'अपने सहयोगियों के कमीशन से आप कुछ प्रतिशत कमीशन पाएंगे।',
    'The commission you get from your affiliates is paid by our financial account and your affiliates’ commission won’t be affected.': 'आपके सहयोगी के कमीशन का भुगतान हमारे वित्तीय अकाउंट से होता है और इससे आपके सहायोगी का कमीशन प्रभावित नहीं होगा।',


    'References': 'संदर',
    '[1] Referral': '[1] ्भ',
    'the friend who you refer the game to': 'रेफरल：जिस मित्र को आप गेम रेफर करते हैं',
    '[2] Division': '[2] डिवीजन',
    'the status of promoter. Five divisions from the lowest to the highest: bronze, silver, gold, platinum and diamond.': 'प्रमोटर की स्थिति। पांच डिवीजन: कांस्य, सिल्‍वर, गोल्‍ड, प्लैटिनम और डॉयमंड।',
    '[3] Affiliate': '[3] सहयोगी',
    'your referral who has shared the game to his friends': 'आपका रेफरल जिसने गेम अपने मित्रों से शेयर किया है',

    'Jan': 'जनवरी',
    'Feb': 'फ़रवरी',
    'Mar': 'मार्च',
    'Apr': 'अप्रैल',
    'May': 'मई',
    'Jun': 'जून',
    'Jul': 'जुलाई',
    'Aug': 'अगस्त',
    'Sep': 'सितंबर',
    'Oct': 'अक्टूबर',
    'Nov': 'नवंबर',
    'Dec': 'दिसंबर',

    'Current Division:': 'वर्तमान डिवीजन:',
    'My Chips': 'माई चिप्स',
    'Trade': 'ट्रेड',
    'Purchase History': 'खरीद हिस्‍ट्री',
    'Discounted Chips': 'छूट प्राप्‍त चिप्स',
    'Rules:': 'नियम:',
    'Date': 'दिनांक',
    'Amount': 'राशि',
    'Status': 'स्थिति',
    'Succeeded': 'सफल हुए',
    'Failed': 'विफल हुए',
    'Processing': 'प्रोसेसिंग',
    'success': 'सफल हुए',
    'fail': 'विफल हुए',
    'pending': 'प्रोसेसिंग',
    'January': 'जनवरी',
    'February': 'फ़रवरी',
    'March': 'मार्च',
    'April': 'अप्रैल',
    'June': 'जून',
    'July': 'जुलाई',
    'August': 'अगस्त',
    'September': 'सितंबर',
    'October': 'अक्टूबर',
    'November': 'नवंबर',
    'December': 'दिसंबर',
    'Recharge': 'रिचार्ज',
    'User ID': 'यूज़र आईडी',
    'Name': 'नाम',
    'Phone Number': 'फ़ोन नंबर',
    'Recharge History': 'रिचार्ज हिस्ट्री',
    'User ID/ Name': 'यूजर आईडी/ नाम',
    'Chips Trade': 'चिप्स ट्रेड',
    'Trade History': 'ट्रेड हिस्‍ट्री',
    'Cancel': 'केंसल',
    'User Name': 'यूजरनेम',
    'Bronze': 'कांस्‍य',
    'Silver': 'सिल्‍वर',
    'Gold': 'गोल्‍ड',
    'Platinum': 'प्लैटिनम',
    'Diamond': 'डॉयमंड',
    'Silver Division': 'सिल्‍वर डिवीजन',
    'Invite More & Earn More': 'अधिक आमंत्रित कर अधिक कमाएँ',
    'Gold Division': 'गोल्‍ड डिवीजन',
    'Referral Commission': 'रेफरल कमीशन',
    'Platinum Division': 'प्लेटिनम डिवीजन',
    'Unlimited Referrals': 'असीमित रेफरल',
    'Unlimited Commission': 'असीमित कमीशन',
    'Up to 20% discounts when buying chips': 'चिप्स की खरीद पर 20% तक छूट',
    'Phone Number Verification': 'फ़ोन नंबर सत्यापन',
    'To keep your commission safe, your identify needs to be verified': 'आपके कमीशन की सुरक्षा हेतु, आपकी पहचान सत्यापित की जानी है',
    'Verify': 'सत्यापित करें',
    'Phone Number:': 'फ़ोन नंबर:',
    'Confirm': 'पुष्टि करें',
    'Welcome Bonus': 'वेलकम बोनस',
    'Welcome to Taurus, and receive ₹20!': 'टॉरस में आपका स्वागत और ₹20 पाएं!',
    'Receive': 'पाएं',
    'Enter your phone number': 'अपना फोन नंबर डालें',
    'Enter Verification code': 'सत्यापन कोड डालें',
    'Send': 'भेजें',
    'The Higher Division You Are': 'जितनी उच्च डिवीजन में होगे',
    'The More Money You Get': 'उतना अधिक पैसा पाओगे',
    'Division': 'डिविजन',
    'Commission from Invitation': 'निमंत्रण से कमीशन',
    'Commission from Recharging': 'रिचार्जिंग से कमीशन',
    'Commission from Affiliates': 'सहयोगियों से कमीशन',
    'Discount Rate for Chips': 'चिप्स के लिए छूट दर',
    'You can buy discounted chips, and earn money by price difference when trading with game players. For example, you spend ₹10000 buying ₹10500 chips, and then you trade the chips with game player at a price of ₹10200. So you earn ₹200 and the game player saves ₹300.': 'गेम खिलाड़ियों से ट्रेड करते समय आप रियायती चिप्स खरीदकर मूल्य अंतर से पैसे कमा सकते हैं। उदाहरण के लिए, ₹10500 चिप्स खरीदने पर आप ₹10000 खर्च कर, ₹10200 की कीमत पर गेम प्लेयर से चिप्स ट्रेड करते हैं। इस तरह आप ₹200 कमाते हैं और गेम खिलाड़ी ₹300 बचाता है।',
    'Click the upper button Trade, to recharge the chips instantly to the account of corresponding game player. The chips can only be sent to your referrals.' : 'इसी गेम खिलाड़ी के अकाउंट में तुरंत चिप्स रिचार्ज करने के लिए ऊपरी बटन ट्रेड क्लिक करें। चिप्स केवल आपके रेफरल को भेजे जा सकते हैं।',
    'Share or copy the link to others. When they download and install the game, they become your referrals.': 'लिंक अपने मित्रों को कॉपी या शेयर करें। उनके गेम डाउनलोड और इंस्टॉल करने पर, वे आपके रेफरल बनते हैं।',
    'The higher division you are, the higher percentage of discount you can get. Your division can be upgraded quickly by encouraging your referrals to recharge in the game.' : 'आपका डिवीजन जितना उच्‍च होगा, उतने अधिक डिस्काउंट रेट मिलेगा। आपके रेफरल को गेम में रिचार्ज के लिए प्रोत्साहित कर आपका डिवीजन तुरंत अपग्रेड किया जा सकता है।',
    'Tips: Trade with Your Referrals': 'टिप्स: आपके रेफरल के साथ व्यापार',
    'search': 'सर्च',
    'No Data': 'कोई आकड़ा उपलब्ध नहीं है',
    'My Referral Link': 'मेरा रेफरल लिंक',
    'Total': 'जोड़',
    'Referral Code': 'रेफरल कोड',
    'Today\'s/Total Commission': 'आज का/कुल कमीशन',
    'The last {0} days': 'आखिरी {0} दिन',
    'Okay': 'ठीक है',
    'Go develop your affiliates!': 'अपने सहयोगियों को विकसित करें!',
    'Log out': 'लॉग आउट',
    'Sign in': 'लॉगिन',
    'Type your OTP': 'आपके OTP टाइप करें',
    'Withdrawal': 'निकासी',
    'Type your UPI ID': 'पनी UPI ID टाइप करें',
    'The withdrawal has been successful and money will be sent to you within 7 working days.': 'निकासी सफल रही है और धन आपको 7 कार्य दिवसों के भीतर भेज दिया जाएगा।',
    'You\'ve reached the maximum number of attempts to get OTP today. Please try again 24 hours later.': 'आप आज ओटीपी प्राप्त करने के लिए अधिकतम प्रयासों तक पहुँच गए हैं। कृपया 24 घंटे बाद पुन: प्रयास करें।',
    'Sharing successful': 'साझा करना सफल रहा',
    'The system is upgrading, please try later.': 'सिस्टम अपग्रेड हो रहा है, कृपया बाद में प्रयास करें।',
    'Tips: Send to a specific user': 'टिप्स: किसी विशिष्ट उपयोगकर्ता को भेजें',
    '* Invalid ID': '* अमान्य पहचान पत्र',
    'Payee (Mobile Number)': 'आदाता (मोबाइल नंबर)',
    'Type User ID': 'उपयोगकर्ता आईडी टाइप करें',
    'Type Amount': 'राशि टाइप करें',
    "Can't recharge for yourself": "अपने आप को रिचार्ज नहीं कर सकते",
    'NAME': 'तुम्हारा नाम',
    'Type your name': 'अपना नाम दर्ज करें',
    'Task 1': 'टास्क 1',
    'Task 2': 'टास्क 2',
    'Task 3': 'टास्क 3',
    'Task 4': 'टास्क 4',
    'Task 5': 'टास्क 5',
    'Share the game to friends': 'दोस्तों को खेल साझा करें',
    'Invite 1 friend to register': 'रजिस्टर करने के लिए 1 मित्र को आमंत्रित करें',
    'Invite 2 friend to register': 'रजिस्टर करने के लिए 2 मित्र को आमंत्रित करें',
    'Invite 4 friend to register': 'रजिस्टर करने के लिए 4 मित्र को आमंत्रित करें',
    'Invite 8 friend to register': 'रजिस्टर करने के लिए 8 मित्र को आमंत्रित करें',
    'Share now': 'अब साझा करें',
    'Invite now': 'अब आमंत्रित करें',
    'Congratulations!': 'बधाई हो!',
}
