import { production } from '../config';

class Logger {
    error() {}

    static keyNames = {
        'tc_home_display_001': ' ',
        'tc_home_back_002': ' ',
        'tc_home_language_003': ' ',
        'tc_home_rule_004': ' ',
        'tc_home_customer_005': ' ',
        'tc_home_display_006': ' ',
        'tc_home_display_007': ' ',
        'tc_home_display_008': ' ',
        'tc_home_display_009': ' ',
        'tc_home_display_010': ' ',
        'tc_home_display_011': ' ',
        'tc_home_display_012': ' ',
        'tc_home_display_013': ' ',
        'tc_home_display_013_1': ' ',
        'tc_home_display_014': ' ',
        'tc_home_display_015': ' ',
        'tc_home_display_016': ' ',
        'tc_home_display_017': ' ',
        'tc_home_display_018': ' ',
        'tc_home_display_019': ' ',
        'tc_home_display_020': ' ',
        'tc_recharge_display_001': ' ',
        'tc_recharge_trade_002': ' ',
        'tc_recharge_buy_003': ' ',
        'tc_recharge_buy_suc_004': ' ',
        'tc_recharge_buy_fail_005': ' ',
        'tc_recharge_history_006': ' ',
        'tc_trade_display_001': ' ',
        'tc_trade_history_002': ' ',
        'tc_trade_send_003': ' ',
        'tc_trade_suc_004': ' ',
        'tc_trade_fail_005': ' ',
        'task_float_click_001': ' ',
        'task_disp_002': ' ',
        'task_share_click_003': ' ',
        'task_invite_click_004': ' ',
        'task_bonus_click_day1_005': ' ',
        'task_bonus_click_day2_006': ' ',
        'task_bonus_click_day3_007': ' ',
        'task_bonus_click_day4_008': ' ',
        'task_bonus_click_day5_009': ' ',
        'task_share_click_wa_010': ' ',
        'task_share_click_fb_011': ' ',
        'task_share_click_copy_012': ' ',
        'task_share_click_more_013': ' ',
        'task_share_click_cl_014': ' ',
        'task_share_click_any_015': ' ',
    };

    static getName(key) {
        return key + '_' + (Logger.keyNames[key] || '');
    }

    info(key, value) {
        let v = Object.assign({}, value||{})
        console.log('try to report event:', Logger.getName(key), v);
        if (production && analytics) {
            if (window.isPreload) {
                console.log('skip to report for preload');
                return;
            }
            let eventName = Logger.getName(key);
            analytics.logEvent(eventName, v);
            console.log(eventName, v);
            let channelId = localStorage.getItem('channelId', '');
            if (channelId == '3100003') {
                eventName = '3100003_' + eventName;
                analytics.logEvent(eventName, v);
                console.log(eventName, v);
            }
            return;
        }
    }
}

export default new Logger();
