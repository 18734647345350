
import axios from 'axios';
import Cookie from '@/utils/cookie';
import { production } from '../config';

// create an axios instance 
const http = axios.create({
    // api base_url
    // baseURL: 'http://192.168.0.6:5682/awclient',
    // baseURL: production ? 'http://13.233.238.138:5682/awclient' : 'http://192.168.0.6:5682/awclient',
    baseURL: production ? 'https://awvun-api.taurus.cash/awclient' : 'http://192.168.0.6:5682/awclient',
    
    timeout: 30000, // request timeout
    responseType: "json",
    withCredentials: true,
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      // "Host": "api.tcvg.pw",
      // "Origin": "api.tcvg.pw",
    }
  });

http.interceptors.request.use(config => {
    let token = Cookie.getCookie('token');
    if (token) {
        config.headers['token'] = token;
    }
    if (!production) {
      config.url = config.url.replace('', '');
    }
    if (config.url.indexOf('pn=')  == -1) {
      if (config.url.indexOf('?') > 0) {
        config.url = config.url + '&pn=aw';
      } else {
        config.url = config.url + '?pn=aw';
      }
    }
    return config;
});

export default http;
